import * as React from "react"
import {Link} from "gatsby"
import Layout from "../components/Layout/Layout";

const NotFoundPage = () => {
    return (
        <Layout>
            <div className="container">
                {process.env.NODE_ENV === "development" ? (
                    <>
                        Try creating a page in <code>src/pages/</code>.
                    </>
                ) : null}

                <h1 className={"pt-5 text-center"}>404</h1>
                <h2 className={"text-center"}>Hledaná stránka neexistuje.</h2>
                <p className={"text-center"}>
                    Je nám líto, že jste nenašli co hledáte. <br />
                    Vrátit se na <Link to="/">hlavní stránku</Link>.
                </p>

            </div>
        </Layout>
    )
}

export default NotFoundPage
